export const en = {
  Generic: {
    appName: 'Wholesale showroom booking tool',
    login: {
      login: 'Login',
      sso: 'Login with SSO',
      support: 'If you need support please contact Versace Reception:',
    },
    logout: 'Logout',
    yes: 'Yes',
    no: 'No',
    man: 'Man',
    woman: 'Woman',
    kids: 'Kids',
    unisex: 'Unisex',
    form: {
      send: 'Send',
      error: 'Please check the form for errors',
    },
    loading: 'Loading…',
    query: {
      success_delete: 'Item successfully deleted',
      error: 'An error occurred. Try again',
      error_E_FORBIDDEN: 'Action not permitted.',
    },
  },
  Errors: {
    genericTitle: 'Error',
    genericMessage: 'Something went wrong, try later',
    requiredField: 'Required field',
    emailFormatError: 'Invalid email format',
    invalidUrl: 'Invalid url',
    invalidForm: 'Please check the form for errors',
    Server: {
      E_EVENT_INVALID_DATES: 'Event with invalid dates, please check the form',
      E_APPOINTMENT_INVALID_TIME:
        'Appointment with invalid times, please check the form',
      ER_DUP_USER: 'User email already registered',
      ER_DUP_BUYER: 'Buyer email already registered',
      ER_USER_DELETED: 'User email already registered in the system',
      ER_BUYER_DELETED: 'Buyer email already registered in the system',
      E_APPOINTMENT_ALREADY_EXISTS: 'The appointment overlaps with another',
    },
  },
  Authentication: {
    authError: 'Authentication Error',
  },
  Login: {
    error: {
      noRole: {
        title:
          'Your account can’t be found in Versace Wholesale showroom booking tool',
        info: 'Please contact Versace Reception:',
      },
      noPermissions: {
        title: 'No content here',
        info: 'Your role does not have access to the content in the Wholesale showroom booking tool',
      },
    },
  },
  HeaderLinks: {
    campaignManagement: 'Campaign Management',
    userManagement: 'User Management',
    campaignList: 'Campaign List',
  },
  Footer: {
    privacyPolicy: 'Privacy Policy',
    cookiePolicy: 'Cookies',
    support: 'Support',
    versaceReception: 'Versace Reception:',
    legal: 'Legal',
    contacts: 'Contacts',
  },
  PageNotFound: {
    title: 'Page not found',
    info: 'Sorry, we couldn’t find the page you are looking for',
  },
  UserManagement: {
    list: {
      title: 'User management',
      addUser: 'Add user',
      addBuyer: 'Add buyer',
      columns: {
        contactName: 'Contact Name',
        role: 'Role',
        contactNumber: 'Contact Number',
        versaceEmail: 'E-Mail Versace',
        externalEmail: 'External E-Mail',
        clientID: 'Client ID',
        storeName: 'Store Name',
      },
    },
    importBuyers: {
      title: 'Import buyers',
      label: 'Drag and drop files here or click to select files',
      sizeLabel: 'Max 10 MB files are allowed',
      downloadTemplate: 'Download template',
      infoColumns: 'The Excel file must contain the following columns:',
      columns:
        'name, surname, email, storeName, clientId, companyName, managerName, managerSurname, managerEmail.',
      upload: 'Upload File',
      success: 'Upload successful ({{successCount}} buyers imported)',
      warning:
        'Upload successful ({{successCount}} buyers imported), but with some error ({{errorsCount}} buyers not imported)',
    },
    addUser: {
      title: 'Add User',
      title_BUYER: 'Add Buyer',
      description:
        'Complete the form and then click save to create a new user for the Wholesale showroom booking tool. Fields with * are required.',
      description_BUYER:
        'Complete the form and then click save to create a new buyer for the Wholesale showroom booking tool. Fields with * are required.',
      success: 'User successfully created',
      success_BUYER: 'Buyer successfully created',
      error: 'Please check the form for errors',
    },
    editUser: {
      title: 'Edit User',
      title_BUYER: 'Edit Buyer',
      description:
        'Complete the form and then click save to update the user. Fields with * are required.',
      description_BUYER:
        'Complete the form and then click save to update the buyer. Fields with * are required.',
      success: 'User edited successfully',
      success_BUYER: 'Buyer successfully updated',
      error: 'Please check the form for errors',
    },
    fields: {
      role: {
        label: 'User Role',
        placeholder: 'Select user role',
      },
      versaceEmail: {
        label: 'Versace E-Mail Address',
        placeholder: 'Enter Versace E-Mail Address',
      },
      clientId: {
        label: 'Client ID',
        placeholder: 'Enter client ID',
        searchPlaceholder: 'Search client ID',
      },
      versaceManager: {
        label: 'Versace Manager',
        placeholder: 'Select Versace Manager',
      },
      storeName: {
        label: 'Fiscal Name or Store Name',
      },
      firstName: {
        label: 'Contact first name',
        placeholder: 'Enter contact first name',
      },
      lastName: {
        label: 'Contact last name',
        placeholder: 'Enter contact last name',
      },
      phoneNumber: {
        label: 'Contact Number',
        placeholder: 'Enter contact number',
      },
      externalEmail: {
        label: 'External E-Mail Address',
        placeholder: 'Enter External E-Mail Address',
      },
      search: {
        label:
          'Search by Contact Name, E-Mail Versace, external E-Mail, Store Name',
        placeholder: 'Search',
      },
      roles: {
        label: 'User role',
      },
    },
    deleteUser: {
      modalTitle: 'Delete user',
      modalContent: 'This user will be deleted. This action can not be undone.',
    },
    noResults: {
      title: 'No results for your search found',
    },
  },
  GenericButton: {
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
  },
  GenericCloseModal: {
    title: 'Confirm close',
    text: 'If you close this section your data will be lost. Do you want to close the section anyway?',
  },
  CampaignManagement: {
    title: 'Campaign Management',
    addCampaign: 'Add Campaign',
    editCampaign: 'Edit Campaign',
    createCampaign: {
      drawer:
        'Complete the form and then click save to create a new campaign. Published campaign will be visible to users with permission, unpublished campaign will be visible only to showroom managers. All fields are required',
      success: 'Campaign created',
      error: 'Please check the form for errors',
      invalidDateError: 'End date must be after start date',
    },
    patchCampaign: {
      published: 'Campaign published successfully',
      unpublished: 'Campaign unpublished successfully',
    },
    updateCampaign: {
      drawer:
        'Complete the form and then click save to update the campaign. Published campaign will be visible to users with permission, unpublished campaign will be visible only to showroom managers. All fields are required',
      success: 'Campaign updated',
      error: 'Please check the form for errors',
    },
    timePicker: {
      title: 'Select Time',
      startDate: 'Start Date*',
      endDate: 'End Date*',
      dateFormat: 'ddd DD/MM/YYYY',
      timeDiffUnit: '{{hours}} hours',
      labelUnit: 'Day {{dayIndex}}',
    },
    fields: {
      name: {
        label: 'Campaign Name',
        placeholder: 'Enter Campaign Name',
      },
      seasonCode: {
        label: 'Season Code',
        placeholder: 'Enter Season Code',
      },
      isPublish: 'Publish',
      seller: {
        label: 'Seller',
        placeholder: 'Select Seller',
        searchPlaceholder: 'Search Seller',
      },
      sortSeller: {
        label: 'Sort seller',
        tooltip: 'Selected order will be displayed in the calendar',
      },
      startDate: {
        label: 'Start Date',
        placeholder: 'Select Start Date',
      },
      endDate: {
        label: 'End Date',
        placeholder: 'Select End Date',
      },
      genderCategory: {
        label: 'Gender Category',
        placeholder: 'Select gender',
      },
    },
    sections: {
      sellerSelection: 'Seller Selection',
      dateSelection: 'Date Selection',
    },
    table: {
      campaignName: 'Campaign Name',
      seasonCode: 'Season Code',
      startDate: 'Start Date',
      endDate: 'End Date',
      dateFormat: 'DD/MM/YYYY',
      actions: {
        publish: 'Publish',
        unpublish: 'Unpublish',
        calendar: 'Calendar',
        download: 'Download',
        edit: 'Edit',
        delete: 'Delete',
      },
    },
    publishedCampaign: {
      title: 'Published Campaigns',
    },
    unpublishedCampaign: {
      title: 'Unpublished Campaigns',
    },
    campaignHistory: {
      title: 'Campaign History',
    },
    deleteCampaign: {
      modalTitle: 'Delete campaign',
      modalContent:
        'This campaign will be deleted. This action cannot be undone.',
    },
    noCampaign: {
      title: 'There are no campaigns',
      title_published: 'There are no published campaigns',
      title_unpublished: 'There are no unpublished campaigns',
      title_history: 'There are no archived campaigns',
      info: 'Click Add Campaign to create a new one',
    },
  },
  Calendar: {
    title: 'Calendar',
    search: {
      placeholder: 'Search',
      noResults: 'No results found',
    },
    actions: {
      history: 'History',
      sendInvitation: 'Send Invitation',
      manageAppointments: 'Manage Appointments',
      editCampaign: 'Edit Campaign',
    },
    categories: 'Category',
    genderCategory: 'Gender Category',
    infoCards: {
      dateFormat: 'DD/MM/YYYY',
      startDate: 'Start Date',
      endDate: 'End Date',
      seasonCode: 'Season Code',
      covers: {
        label: 'Covers',
        value: 'Bar: {{bar}} | Lunch: {{lunch}}',
      },
      totalAppointments: 'Total Appointments',
      dailyAppointments: 'Daily Appointments',
      sellers: 'Sellers',
    },
    addAppointment: 'Add Appointment',
    scheduler: {
      currentDateFormat: 'ddd LL',
    },
  },
  UserRoles: {
    SHOWROOM_MANAGER: 'Showroom Manager',
    SALES_MANAGER: 'Sales Manager',
    RECEPTIONIST: 'Receptionist',
    READ_ONLY: 'Read Only',
    SELLER: 'Seller',
    BUYER: 'Buyer',
  },
  FormValidation: {
    email: 'should be a valid e-mail',
    phoneNumber: 'should be a valid phone number',
    alphabetic: 'should contain only alphabetic characters',
    alphanumeric: 'should contain only alphanumeric characters',
    required: 'is required',
    minDate: 'Minimal allowed date is ',
    maxDate: 'Maximal allowed date is ',
    dateFormat: 'DD/MM/YYYY',
    maxLength: 'should contain only {{maxLength}} characters',
  },
  AppointmentCard: {
    variantText: {
      presence: 'Presence meeting',
      online: 'Online meeting',
      wt: 'WT meeting',
      pending: 'Pending',
    },
  },
  AppointmentTooltip: {
    moreDetails: 'more details',
    clientId: 'Client ID',
    manager: 'Manager',
    buyers: 'Buyers',
    success_delete: 'Appointment successfully deleted',
  },
  AppointmentMoreInfo: {
    description: 'Description',
    buyersInfo: 'Buyers information',
    contactBuyer: 'Contact Buyer',
    buyer: 'Buyer {{index}}',
  },
  AppointmentTags: {
    categories: 'Cat. {{number}}',
    allCategories: 'ALL CAT',
  },
  AddAppointment: {
    title_add: 'Add Appointment',
    title_edit: 'Edit Appointment',
    description_add:
      'Complete the form and then click save to add a new appointment. A confirmation email will be sent to the buyers. Fields with * are required.',
    description_edit:
      'Complete the form and then click save to edit the appointment. If you change date and time a mail will be sent to the buyers. Fields with * are required.',
    sections: {
      appointmentInfo: 'Appointment Information',
      buyersInfo: 'Buyers Information',
    },
    fields: {
      date: {
        label: 'Appointment Date',
        placeholder: 'Select Appointment Date',
      },
      startTime: {
        label: 'Start Time',
        placeholder: 'Select Start Time',
      },
      endTime: {
        label: 'End Time',
        placeholder: 'Select End Time',
      },
      timeInfo:
        '{{minutes}} minutes will be automatically added before and after the meeting',
      categories: {
        label: 'Categories',
        placeholder: 'Select Categories',
        selectionType: 'category',
        selectionTypePlural: 'categories',
      },
      meetingType: {
        label: 'Type of meeting',
        placeholder: 'Select type of meeting',
        values: {
          person: 'ONSITE',
          online: 'VIRTUAL',
          wt: 'WT',
        },
        warning: 'Changing meeting type will delete buyers',
      },
      seller: {
        label: 'Seller',
        placeholder: 'Select Seller',
      },
      storeName: {
        label: 'Store Name',
        placeholder: 'Select Store Name',
        searchPlaceholder: 'Search Store Name',
      },
      contactBuyer: {
        label: 'Contact Buyer',
        placeholder: 'Select Contact Buyer',
        tooltip:
          'When you select a contact buyer it won’t appear in buyers selection',
        searchPlaceholder: 'Search Contact Buyer',
      },
      buyers: {
        label: 'Buyers',
        labelMax: 'Buyers ({{count}} max)',
        placeholder: 'Select Buyers',
        searchPlaceholder: 'Search Buyers',
        selectionType: 'buyer',
        selectionTypePlural: 'buyers',
      },
      genderCategory: {
        label: 'Gender Category',
        placeholder: 'Select gender',
      },
    },
    success_add: 'Appointment created',
    success_edit: 'Appointment updated',
    error: 'Please check the form for errors',
  },
  MultiSelect: {
    selectedNumber: '{{count}} {{type}} selected',
    selectedNumber_plural: '{{count}} {{pluralType}} selected',
    selectAll: 'Select All',
  },
  DeleteAppointment: {
    modalTitle: 'Delete appointment',
    modalContent:
      'This appointment will be deleted. This action cannot be undone. A cancellation e-mail will be sent to the buyers.',
  },
  CampaignList: {
    title: 'Campaign List',
  },
  CheckIn: {
    checkIn: 'Check In',
    meetingStarted: 'Meeting Started',
    meetingEnded: 'Meeting Ended',
    checkOut: 'Check Out',
    checkInSuccess: 'Checkin done successfully',
    meetingStartedSuccess: 'Meeting started successfully',
    meetingEndedSuccess: 'Meeting ended successfully',
    checkOutSuccess: 'Checkout done successfully',
  },
  AppointmentsManagement: {
    title: 'Appointments Management',
    newRequests: 'New requests',
    pendingRequests: 'Pending requests',
    reschedule: 'Reschedule',
    confirmForm: {
      title: 'Versace Appointment Request - {{buyerName}}',
      description:
        'Before confirming the appointment please check the information down below.',
      fields: {
        date: 'Date',
        startTime: 'Start Time',
        endTime: 'End Time',
        campaignId: 'Campaign ID',
        seller: {
          label: 'Seller',
          placeholder: 'Select Seller',
        },
        categories: {
          label: 'Categories',
          placeholder: 'Select Categories',
          selectionType: 'category',
          selectionTypePlural: 'categories',
        },
        typeOfMeeting: 'Type of meeting',
        description: {
          label: 'Description',
          placeholder: 'Write here your description',
        },
        contactBuyer: 'Contact Buyer',
        genderCategory: {
          label: 'Gender Category',
          placeholder: 'Select gender',
        },
      },
      dateFormat: 'DD/MM/YYYY',
      timeFormat: 'LT',
      sections: {
        buyersInfo: 'Buyers Information',
      },
      success: 'Appointment confirmed',
    },
    requestCard: {
      date: '{{- date}}   {{startTime}} - {{endTime}}',
      dateFormat: 'DD/MM/YYYY',
      timeFormat: 'LT',
    },
  },
  AppointmentsRescheduling: {
    title: 'Appointments Rescheduling',
    infoBox: {
      storeName: 'Store Name',
      versaceEmail: 'Versace e-mail address',
      clientId: 'Client ID',
      contactBuyer: 'Contact Buyer',
      contactBuyerEmail: 'Contact Buyer e-mail address',
      contactBuyerPhone: 'Contact Buyer phone number',
    },
    description:
      'To reschedule the appointment, please change the information on the left. On the calendar, to the right, you will see a preview of the appointment',
    send: 'Send',
    fields: {
      date: {
        label: 'Date',
        placeholder: 'Select Date',
      },
      startTime: {
        label: 'Start Time',
        placeholder: 'Select Start Time',
      },
      endTime: {
        label: 'End Time',
        placeholder: 'Select End Time',
      },
      seller: {
        label: 'Seller',
        placeholder: 'Select Seller',
      },
    },
    success: 'Appointment rescheduled',
  },
  AppointmentRequest: {
    title: 'Versace Appointment Request',
    description:
      'To book your appointment, please choose a date. Then press the + button to choose your preferred time and seller. Click send to submit the request.',
    infoBox: {
      storeName: 'Store Name',
      versaceEmail: 'Versace Email Address',
    },
    dateFormat: 'L',
    dateLabel: 'Date (MM/DD/YYYY)',
    send: 'Send',
    selectSlotForm: {
      title: 'Your Appointment',
      fields: {
        startTime: {
          label: 'Start Time',
          placeholder: 'Pick a time',
        },
        endTime: {
          label: 'End Time',
          placeholder: 'Pick a time',
        },
        seller: {
          label: 'Seller',
          placeholder: 'Select a seller',
        },
      },
    },
    moreInfoForm: {
      title: 'Other Information',
      description:
        'Please add few more information about the appointment you want to book.',
      fields: {
        categories: {
          label: 'Categories',
          placeholder: 'Select categories',
          selectionType: 'category',
          selectionTypePlural: 'categories',
        },
        genderCategory: {
          label: 'Gender Category',
          placeholder: 'Select gender',
        },
        meetingType: {
          label: 'Type of meeting',
          placeholder: 'Select type of meeting',
          warning: 'Changing meeting type will delete buyers',
        },
        buyersInfo: {
          title: 'Buyers Information',
          note: 'Presence meeting can host a maximum of three buyers',
        },
        contactBuyer: 'Contact Buyer',
        buyer: 'Buyer {{index}}',
        email: {
          label: 'E-Mail address',
          placeholder: 'Enter contact e-mail address',
        },
        firstName: {
          label: 'First Name',
          placeholder: 'Enter contact first name',
        },
        lastName: {
          label: 'Last Name',
          placeholder: 'Enter contact last name',
        },
        phoneNumber: {
          label: 'Phone Number',
          placeholder: 'Enter contact phone number',
        },
        addBuyer: 'Add a buyer',
        privacyTerms: {
          label: 'I agree to the Privacy policy',
          linkLabel: 'terms',
        },
      },
    },
    success: 'Appointment request sent correctly!',
  },
  AppointmentRequestSuccess: {
    title: 'Versace Appointment Request',
    infoBox: {
      storeName: 'Store Name',
      versaceEmail: 'Versace Email Address',
      contactBuyer: 'Contact Buyer',
      phoneNumber: 'Phone Number',
      externalEmail: 'External Email Address',
    },
    thankYou: 'Thank you for your request',
    feedback:
      'As soon as we review your request you’ll have a feedback from Versace',
  },
  MeetingType: {
    PERSON: 'ONSITE',
    ONLINE: 'VIRTUAL',
    WT: 'WT',
  },
  CampaignHistory: {
    title: 'History',
    filters: {
      action: {
        label: 'Action',
        options: {
          all: 'All',
          created: 'Created',
          updated: 'Updated',
          deleted: 'Deleted',
        },
      },
      fulltext: {
        label: 'Search by Buyer or Store Name',
        placeholder: 'Search buyers or store name',
      },
    },
    list: {
      columns: {
        id: 'ID',
        store: 'Store',
        appointmentDate: 'Appointment date',
        buyers: 'Buyers',
        action: 'Action',
        editor: 'Editor',
        editedOn: 'Edited on',
      },
    },
  },
  SendCampaignInvitation: {
    title: 'Send Invitation',
    fields: {
      recipients: {
        label: 'Recipients',
        placeholder: 'Select Recipients',
      },
      emailSubject: {
        label: 'E-Mail Subject',
        placeholder: 'Write e-mail subject',
      },
      emailLanguage: {
        label: 'E-Mail Language',
        eng: 'ENG',
        ita: 'ITA',
      },
      emailIntro: 'E-Mail Intro',
      additionalInfo: {
        label: 'Additional Information',
        placeholder:
          'Write here any additional information that you want the buyers to read',
      },
      emailOutro: 'E-Mail Outro',
      yourSignature: {
        label: 'Your Signature',
        emailAddress: 'E-Mail Address',
        phoneNumber: 'Phone Number',
      },
      footer: 'Footer',
    },
    success: 'Invitation sent successfully',
  },
  AppointmentReschedule: {
    title: 'What do you want to do?',
    text: 'Please review your scheduled appointment information and choose if you want to confirm it or prefer to reschedule it yourself.',
    date: 'Date',
    dateFormat: 'L',
    startTime: 'Start Time',
    endTime: 'End Time',
    timeFormat: 'LT',
    sellerName: 'Seller Name',
    reschedule: 'Reschedule',
    success: 'Appointment confirmed correctly',
  },
  AppointmentRescheduleSuccess: {
    title: 'Versace Appointment Management',
    infoBox: {
      storeName: 'Store Name',
      versaceEmail: 'Versace Email Address',
      contactBuyer: 'Contact Buyer',
      phoneNumber: 'Phone Number',
      externalEmail: 'External Email Address',
    },
    thankYou: 'Thank-you for your confirmation',
    feedback: 'We’ll wait you in our Milan showroom, Via Gesù 12!',
  },
  CampaignExport: {
    title: 'Download Campaign',
    description:
      'Please choose the format you prefer to download campaign calendar and details.',
    excel: 'Excel',
    pdf: 'PDF',
    download: 'Download',
    success: 'Download successful',
  },
  BuyerMultiSelect: {
    searchPlaceholder: 'Search store',
    selectAll: 'Select All',
    selectedNumber: '{{count}} recipient selected',
    selectedNumber_plural: '{{count}} recipients selected',
  },
  SellerMultiSelect: {
    selectedNumber: '{{count}} seller selected',
    selectedNumber_plural: '{{count}} sellers selected',
  },
  DatePickerMUI: {
    dateFormat: 'DD/MM/YYYY',
  },
  TimePicker: {
    applyToAll: 'Apply to all fields',
  },
};
